import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faArrowCircleRight, faBars, faTimes } from '@fortawesome/pro-regular-svg-icons';
import campaignService from '~/api/services/campaignService';
import { MainLogo, MobileLogo } from '~/components/InlineSVG';
import LSTVLink from '~/components/LSTVLink';
import SimpleSearch from '~/components/Search/SimpleSearch';
import { useDirectories } from '~/hooks/useDirectories';
import { SearchProvider, searchSources } from '~/hooks/useSearch';
import { useAuthService } from '~/api/hooks/useAuthService';
import * as S from './HeaderBar.styles';
import UserMenuDropdown, { UserMenuItems } from './UserMenuDropdown';
import { NavBarModal, useNavBar } from '~/hooks/useNavBar';
import { CtaButton, CtaColorScheme } from '~/components/cta/regular';
import { Input } from '~/components/forms/StyledForm';

const HeaderBar = () => {
    return (
        <S.HeaderBarContainer>
            <DesktopHeader />
        </S.HeaderBarContainer>
    );
};

interface HeaderModalProps {
    show: boolean;
    onMouseEnter: () => void;
    onMouseLeave: () => void;
}

const VendorModal: React.FC<HeaderModalProps> = ({ onMouseEnter, onMouseLeave, show }) => {
    const { directories } = useDirectories();
    return (
        <S.HeaderModal
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{ display: show ? 'block' : 'none' }}
        >
            <S.VendorModalLayout>
                <S.VendorModalLeftSide>
                    <S.VendorModalLeftHeader>Explore Vendors</S.VendorModalLeftHeader>
                    <S.VendorModalLeftText>Explore all vendors in every location</S.VendorModalLeftText>
                    <LSTVLink to="/wedding-vendors">
                        View All <FontAwesomeIcon icon={faArrowCircleRight} style={{ marginLeft: 3 }} />
                    </LSTVLink>
                </S.VendorModalLeftSide>
                <div>
                    <S.HeaderModalTitle>Choose from categories</S.HeaderModalTitle>
                    {directories && (
                        <S.DirectoryGrid>
                            {directories.map((dir) => (
                                <S.DirectoryTile key={dir.id} to={`/${dir.slug}`}>
                                    <S.DirectoryImage imageUrl={dir.bg_image_url} color={dir.bg_color} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>{dir.name}</div>
                                        <FontAwesomeIcon icon={faArrowCircleRight} />
                                    </div>
                                </S.DirectoryTile>
                            ))}
                        </S.DirectoryGrid>
                    )}
                </div>
            </S.VendorModalLayout>
        </S.HeaderModal>
    );
};

const MoreModalLink: React.FC<{ to: string; onClick: () => void }> = (props) => (
    <S.MoreModalLink to={props.to} onClick={() => props.onClick()}>
        {props.children}
        <FontAwesomeIcon icon={faArrowCircleRight} />
    </S.MoreModalLink>
);

const MoreModalUserMenu: React.FC = () => {
    const { ready, loggedIn, goToLogin, goToSignUp } = useAuthService();
    const { setModal } = useNavBar();

    const handleSignUp = () => {
        setModal('none');
        goToSignUp();
    };
    const handleLogin = () => {
        setModal('none');
        goToLogin();
    };
    if (!ready) return null;
    return (
        <S.MobileOnly>
            {loggedIn ? (
                <UserMenuItems />
            ) : (
                <>
                    <S.MoreModalSignInButton onClick={handleLogin}>Login</S.MoreModalSignInButton>
                    <S.MoreModalSignInButton onClick={handleSignUp}>Sign Up</S.MoreModalSignInButton>
                </>
            )}
        </S.MobileOnly>
    );
};

const MoreModal: React.FC<HeaderModalProps> = ({ onMouseEnter, onMouseLeave, show }) => {
    const { setModal } = useNavBar();
    const [email, setEmail] = React.useState('');
    const subscribe = async (e: React.MouseEvent) => {
        e.preventDefault();
        await campaignService.subscribeWithPop(email, 'TyP6hy');
    };
    const hideModal = () => setModal('none');
    const showVendorModal = (e: React.MouseEvent) => {
        e.preventDefault();
        setModal('vendors');
    };

    return (
        <S.HeaderModal
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{ display: show ? 'block' : 'none' }}
        >
            <S.HeaderModalInner>
                <S.MoreModalFirstCategory>Watch &amp; Browse</S.MoreModalFirstCategory>

                <S.MobileOnly>
                    <MoreModalLink to="/wedding-videographers" onClick={hideModal}>
                        Find Videographers
                    </MoreModalLink>
                    <S.MoreModalLink to="#" onClick={showVendorModal}>
                        Explore Vendors
                        <FontAwesomeIcon icon={faArrowCircleRight} />
                    </S.MoreModalLink>
                    <MoreModalLink to="/wedding-videos" onClick={hideModal}>
                        Watch Videos
                    </MoreModalLink>
                    <MoreModalLink to="/wedding-advice" onClick={hideModal}>
                        Get Advice
                    </MoreModalLink>
                </S.MobileOnly>

                <MoreModalLink to="/wedding-fashion" onClick={hideModal}>
                    Discover Fashion
                </MoreModalLink>
                <MoreModalLink to="/wedding-styles" onClick={hideModal}>
                    Browse Styles
                </MoreModalLink>
                <MoreModalLink to="/live" onClick={hideModal}>
                    Watch Live TV
                </MoreModalLink>
                <MoreModalLink to="/wedding-talk" onClick={hideModal}>
                    Wedding Talk
                </MoreModalLink>
                <S.MoreModalCategory>About Love Stories TV</S.MoreModalCategory>
                <MoreModalLink to="/wedding-film-awards" onClick={hideModal}>
                    Love Stories TV Awards
                </MoreModalLink>
                <MoreModalLink to="/contact-us" onClick={hideModal}>
                    Help
                </MoreModalLink>

                <S.SubscribeContainer>
                    <form>
                        <div style={{ flexGrow: 1 }}>
                            <Input
                                placeholder="Your Email Address"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div style={{ marginLeft: 10 }}>
                            <CtaButton
                                caption="Subscribe"
                                $colorScheme={CtaColorScheme.Dark}
                                onClick={(e) => subscribe(e)}
                            />
                        </div>
                    </form>
                    <div>
                        By signing up, I agree to the <a href="/terms-of-use">Terms</a> and{' '}
                        <a href="/privacy-policy">Privacy Policy</a>.
                    </div>
                </S.SubscribeContainer>

                <MoreModalUserMenu />
            </S.HeaderModalInner>
        </S.HeaderModal>
    );
};

interface HeaderLinkProps {
    to: string;
    onMouseEnter: () => void;
    onClick?: (e: any) => void;
    onMouseLeave?: () => void;
    $selected?: boolean;
}
const HeaderLink: React.FC<HeaderLinkProps> = ({
    to,
    onMouseEnter,
    onMouseLeave,
    onClick,
    $selected = false,
    children,
}) => {
    return (
        <S.StyledLink
            to={to}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
            onMouseLeave={onMouseLeave}
            $selected={$selected}
        >
            <S.StyledButton>{children}</S.StyledButton>
        </S.StyledLink>
    );
};

const UserMenu: React.FC = () => {
    const { ready, user, loggedIn, goToLogin, goToSignUp } = useAuthService();
    if (!ready) return <S.DesktopOnly></S.DesktopOnly>;
    return (
        <S.DesktopOnly>
            {loggedIn ? (
                <UserMenuDropdown />
            ) : (
                <S.SignInButtons>
                    <S.StyledButton onClick={goToLogin} style={{ marginRight: 20 }}>
                        Sign In
                    </S.StyledButton>
                    <S.SignInButton onClick={goToSignUp}>Sign Up</S.SignInButton>
                </S.SignInButtons>
            )}
        </S.DesktopOnly>
    );
};

const DesktopHeader = () => {
    const { modal, setModal } = useNavBar();
    const [tab, setTab] = React.useState<NavBarModal>('none');

    const hoverTimeout = React.useRef<NodeJS.Timeout>();

    const restoreTab = React.useCallback(() => setTab(modal), [modal]);

    const showModal = React.useCallback(
        (e: any, newModal: NavBarModal) => {
            e.preventDefault();
            clearTimeout(hoverTimeout.current);
            setModal(newModal);
            setTimeout(restoreTab, 250);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [modal, setModal]
    );

    const delayShowModal = React.useCallback(
        (newModal: NavBarModal) => {
            clearTimeout(hoverTimeout.current);
            restoreTab();
            hoverTimeout.current = setTimeout(() => setModal(newModal), 250);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setModal]
    );

    const toggleModal = (e: any, newModal: NavBarModal) => {
        e.preventDefault();
        clearTimeout(hoverTimeout.current);
        setModal(modal !== 'none' ? 'none' : newModal);
        setTab(modal !== 'none' ? 'none' : newModal);
    };

    const clickVendorsCB = React.useCallback((e) => showModal(e, 'vendors'), [showModal]);
    const mouseEnterVendorsCB = React.useCallback(() => delayShowModal('vendors'), [delayShowModal]);
    const clickMoreCB = React.useCallback((e) => showModal(e, 'more'), [showModal]);
    const mouseEnterMoreCB = React.useCallback(() => delayShowModal('more'), [delayShowModal]);
    const mouseLeaveCB = React.useCallback(() => {
        clearTimeout(hoverTimeout.current);
        restoreTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTab, modal]);
    const mouseEnterNoneCB = React.useCallback(() => delayShowModal('none'), [delayShowModal]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const mouseLeaveNoneCB = React.useCallback(() => restoreTab(), [modal]);
    return (
        <>
            <S.HeaderContainer>
                <S.DesktopLogoLink to="/">
                    <MainLogo />
                </S.DesktopLogoLink>
                <S.MobileLogoLink to="/">
                    <MobileLogo />
                </S.MobileLogoLink>

                <S.SearchBarWrapper>
                    <SearchProvider>
                        <SimpleSearch source={searchSources.header} />
                    </SearchProvider>
                </S.SearchBarWrapper>

                <S.HeaderLinks>
                    <HeaderLink
                        to="/wedding-videographers"
                        onMouseEnter={mouseEnterNoneCB}
                        onMouseLeave={mouseLeaveNoneCB}
                    >
                        Find Videographers
                    </HeaderLink>
                    <HeaderLink
                        to="#"
                        onClick={clickVendorsCB}
                        onMouseEnter={mouseEnterVendorsCB}
                        onMouseLeave={mouseLeaveCB}
                        $selected={tab === 'vendors'}
                    >
                        Explore Vendors
                    </HeaderLink>
                    <HeaderLink to="/wedding-videos" onMouseEnter={mouseEnterNoneCB} onMouseLeave={mouseLeaveNoneCB}>
                        Watch Videos
                    </HeaderLink>
                    <HeaderLink to="/wedding-advice" onMouseEnter={mouseEnterNoneCB} onMouseLeave={mouseLeaveNoneCB}>
                        Get Advice
                    </HeaderLink>
                    <HeaderLink
                        to="#"
                        onClick={clickMoreCB}
                        onMouseEnter={mouseEnterMoreCB}
                        onMouseLeave={mouseLeaveCB}
                        $selected={tab === 'more'}
                    >
                        <FontAwesomeIcon color="#323233" icon={faEllipsisH} />
                    </HeaderLink>
                </S.HeaderLinks>

                <S.MobileOnly>
                    <S.StyledLink to="#" onClick={(e) => toggleModal(e, 'more')}>
                        <S.StyledButton style={{ width: 15 }}>
                            {modal !== 'none' ? (
                                <FontAwesomeIcon color="#323233" icon={faTimes} />
                            ) : (
                                <FontAwesomeIcon color="#323233" icon={faBars} />
                            )}
                        </S.StyledButton>
                    </S.StyledLink>
                </S.MobileOnly>

                <UserMenu />
            </S.HeaderContainer>

            <VendorModal
                show={modal === 'vendors'}
                onMouseEnter={() => clearTimeout(hoverTimeout.current)}
                onMouseLeave={() => delayShowModal('none')}
            />
            <MoreModal
                show={modal === 'more'}
                onMouseEnter={() => clearTimeout(hoverTimeout.current)}
                onMouseLeave={() => delayShowModal('none')}
            />
        </>
    );
};

export default HeaderBar;
